import React, { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../Components/utils/Loader";
import Layout from "../views/layouts/Layout.jsx";

const Home = lazy(() => import("../views/pages/Home/Home"));
const About = lazy(() => import("../views/pages/About/About"));
const PrivacyPolicy = lazy(() => import("../views/pages/PrivacyPolicy/index"));
const ContactUs = lazy(() => import("../views/pages/Contact/Contact"));
const Career = lazy(() => import("../views/pages/Career/index"));
const Ourblogs = lazy(() => import("../views/pages/Blog/index"));
const TechnologiesPage = lazy(() =>
  import("../views/pages/Technologies/index")
);
const Singleblog = lazy(() => import("../views/pages/singleblog/Singleblog"));
const Team = lazy(() => import("../views/pages/Team/Team"));

const Brandmybuisness = lazy(() =>
  import("../Components/Home/ServiceTags/Brandmybuisness.jsx")
);

const Consultation = lazy(() =>
  import("../Components/Home/ServiceTags/Consultation.jsx")
);

// import Home from "../views/pages/Home/Home";
// import About from "../views/pages/About/About";
// import PrivacyPolicy from "../views/pages/PrivacyPolicy/index.jsx";
// import ContactUs from "../views/pages/Contact/Contact.jsx";
// import Career from "../views/pages/Career/index.jsx";
// import Ourblogs from "../views/pages/Blog/index.jsx";
// import TechnologiesPage from "../views/pages/Technologies/index.jsx";
// import Singleblog from "../views/pages/singleblog/Singleblog.jsx";
// import Team from "../views/pages/Team/Team.jsx";
// import Brandmybuisness from "../Components/Home/ServiceTags/Brandmybuisness.jsx";

const Routes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "/about",
          element: (
            <Suspense fallback={<Loader />}>
              <About />
            </Suspense>
          ),
        },
        {
          path: "/privacy-policy",
          element: (
            <Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "/contact",
          element: (
            <Suspense fallback={<Loader />}>
              <ContactUs />
            </Suspense>
          ),
        },
        {
          path: "/career",
          element: (
            <Suspense fallback={<Loader />}>
              <Career />
            </Suspense>
          ),
        },
        {
          path: "/blogs",
          element: (
            <Suspense fallback={<Loader />}>
              <Ourblogs />
            </Suspense>
          ),
        },
        {
          path: "/blog",
          element: (
            <Suspense fallback={<Loader />}>
              <Singleblog />
            </Suspense>
          ),
        },
        {
          path: "/team",
          element: (
            <Suspense fallback={<Loader />}>
              <Team />
            </Suspense>
          ),
        },
        {
          path: "/technology",
          element: (
            <Suspense fallback={<Loader />}>
              <TechnologiesPage />
            </Suspense>
          ),
        },
        // {
        //   path: "/brandMybuisness",
        //   element:(
        //     <Suspense fallback={<Loader />}>
        //       <Brandmybuisness/>
        //     </Suspense>
        //   )
        // }
      ],
    },
    {
      path: "/brandMybuisness",
      element: (
        <Suspense fallback={<Loader />}>
          <Brandmybuisness />
        </Suspense>
      ),
    },
    {
      path: "/consultation",
      element: (
        <Suspense fallback={<Loader />}>
          <Consultation />
        </Suspense>
      ),
    },
    // {
    //   path: "*",
    //   element: <Navigate to="404" replace />,
    // },
  ]);

  return element;
};

export default Routes;
